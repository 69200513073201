<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Vuokrakohde</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <v-card class="mt-2">
      <v-card-title>
        <div>
          <span
            >{{ currentApartment.address
            }}<span>{{
              currentApartment.apartmentNumber ? `, ${currentApartment.apartmentNumber}` : ""
            }}</span>
          </span>
          <p v-if="!currentApartment.active" class="error--text" style="font-size: 13px">
            Kohde poistettu
          </p>
        </div>
        <v-spacer></v-spacer>
        <router-link :to="`/apartment/edit/${$route.params.id}`">
          <v-btn color="primary">Muokkaa</v-btn>
        </router-link>
      </v-card-title>

      <v-container fluid>
        <v-row dense>
          <v-col>
            <!-- TABS -->
            <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab v-for="item in permissionTabs" :key="item.text" :href="`#${item.text}`">
                <div>{{ item.text }}</div>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <!-- Images -->
        <Images
          v-if="tab == 'Kuvat'"
          bucket="vuokranet-kohdekuvat"
          model="apartment"
          align="center"
        ></Images>

        <!-- Info -->
        <apartment-info v-if="tab == 'Yhteenveto'" sm="12" md="5" lg="5" xl="3"></apartment-info>
        <!-- Renovations -->
        <apartment-renovations v-if="tab == 'Remontit'"></apartment-renovations>
        <!-- Receipts -->
        <apartment-receipts v-if="tab == 'Kulut'"></apartment-receipts>
        <!-- Notes -->
        <apartment-notes v-if="tab == 'Muistiinpanot'"></apartment-notes>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import ApartmentRenovations from "../../components/Apartment/ApartmentRenovations.vue";
import ApartmentReceipts from "../../components/Receipts/ApartmentReceipts";
import Images from "../../components/Image/Images.vue";
import ApartmentNotes from "../../components/Apartment/ApartmentNotes.vue";
import ApartmentInfo from "../../components/Apartment/ApartmentInfo.vue";
import { mapActions, mapState } from "vuex";

export default {
  title: "Kohteen tiedot",

  components: {
    ApartmentRenovations,
    ApartmentReceipts,
    Images,
    ApartmentNotes,
    ApartmentInfo,
  },

  mixins: [mixins],

  data() {
    return {
      tab: 0,
      tabs: [
        { text: "Yhteenveto", resource: "apartment", permission: "read" },
        { text: "Muistiinpanot", resource: "note", permission: "read" },
        { text: "Kulut", resource: "apartment", permission: "receipts" },
        { text: "Kuvat", resource: "apartment", permission: "images" },
        { text: "Remontit", resource: "apartment", permission: "renovations" },
      ],
      apartment: {
        address: "",
        apartmentNumber: "",
      },
    };
  },

  computed: {
    ...mapState("apartment", ["currentApartment"]),

    permissionTabs() {
      return this.tabs.filter((tab) => {
        if (this.isAuthorized(tab.resource, tab.permission)) {
          return true;
        }
      });
    },
  },

  methods: {
    ...mapActions("apartment", ["getOneApartment"]),
  },

  created() {
    this.getOneApartment(this.$route.params.id);
  },
};
</script>

<style scoped>
.isPremium {
  color: rgb(216, 216, 216);
}
</style>
